<template>
  <div id="app" class="body fixed mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <!-- <div class="spinner"><div><div></div></div></div> -->
    <!-- Header -->
    <section class="header website">
      <div class="flexH width">
        <a class="icon iconButton third" @click.prevent="$router.push('/profile')">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span class="fL nowrap">市政資訊</span>
      <div class="flexH width right"></div>
    </section>
    <!-- Main -->
    <section class="main website">
      <a href="https://www.tycg.gov.tw/ch/">
        <span>桃園市政府</span>
        <img src="@/assets/img/tygov.png" />
      </a>
      <a href="https://taotalk.tycg.gov.tw/">
        <span>桃園市政信箱</span>
        <img src="@/assets/img/tymailbox.png" />
      </a>
      <a href="https://e-services.tycg.gov.tw/TycgOnline/tycgOnline.action">
        <span>桃園網路e指通</span>
        <img src="@/assets/img/tye.png" />
      </a>
      <a href="https://covid-19.tycg.gov.tw/">
        <span>桃園COVID-19防疫資訊站</span>
        <img src="@/assets/img/tylogo.png" />
      </a>
    </section>
  </div>
</template>
<script>
export default {
  name: 'Websites',
  data() {
    return {};
  },
};
</script>
